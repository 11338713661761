<template>
  <div class="page">
    <div class="content">
      <div class="title">搜索结果：</div>
      <div class="tables">
        <div
          class="item"
          v-for="(item, index) in goods"
          :key="index"
          @click="toDetail(item.product.id)"
        >
          <img class="img" :src="$host + item.image" />
          <div class="price">
            <span style="font-size: 16px">￥</span
            >{{ item.product ? item.product.price : 0 }}
          </div>
          <div class="title">{{ item.name }}</div>
        </div>
      </div>

      <div
        style="width: 1200px; margin-top: 30px; text-align: center"
        v-show="count > params.limit"
      >
        <b-pagination v-model="params.page" :total-rows="count" :per-page="params.limit"></b-pagination>
      </div>
      <Empty v-show="count == 0"></Empty>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goods: [],
      params: {
        page: 1,
        limit: 8,
      },
      count: 0,
      search: ""
    };
  },
  watch: {
      "params.page":function() {
          this.getList()
      },
      
  },
  methods: {
    toDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/goods_info?id="+id,
      });
    },
    getList() {
      let data = this.params;
      data.search = this.search
      this.$http.shop_goodslist(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.goods = data.goods_list.items;
          this.count = data.goods_list.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
  mounted() {
    this.$EventBus.$on("searchEvent", msg => {      
        this.search = msg
        this.getList();
    })     
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.content {
  width: 1200px;
  margin: 20px 0;
  .title {
    height: 40px;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }

  .tables {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-top: 20px;
      width: 300px;
      border: 1px solid #f0f0f0;
      padding: 20px 20px;
      .img {
        width: 260px;
        height: 260px;
        object-fit: contain;
      }
      .price {
        font-size: 24px;
        color: #ff7690;
        height: 44px;
        line-height: 44px;
      }
      .title {
        width: 260px;
        font-size: 18px;
        color: #999;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
      }
    }
    .item:hover {
      cursor: pointer;
    }
  }
}
</style>
